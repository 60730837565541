<script setup lang="ts">
const route = useRoute()
const { data: page } = await useAsyncData(`${route.path}`, () =>
  queryContent(route.path).findOne(),
)

if (!page.value) {
  throw createError({ statusCode: 404, statusMessage: 'Page not found' })
}

const runtimeConfig = useRuntimeConfig()
const appConfig = useAppConfig()
const { appName, email } = useAppConfig()

useContentHead(page.value)

useHead({
  titleTemplate: `${appName}`,
  // meta: [{ name: 'description', content: () => t('global.app_description') }],
})

const config = useRuntimeConfig()
useSeoMeta({
  title: `${page.value.title} - ${appName}`,
  description: () => appConfig.appDescription,
  author: 'Ekopii',
  ogImage: page.value.image || '/ekopii-limited-logo.png',
  ogType: 'website',
  ogTitle: `${page.value.title} - ${appName}`,
  ogDescription: () => appConfig.appDescription,
  ogUrl: () => config.public.baseUrl + route.path,
  ogLocale: () => 'en',
  twitterTitle: `${page.value.title} - ${appName}`,
  twitterDescription: () => appConfig.appDescription,
  twitterCard: 'summary_large_image',
  twitterSite: appConfig.twitterUsername,
  twitterCreator: appConfig.twitterUsername,
  twitterImage: page.value.image || '/ekopii-limited-logo.png',
})
</script>
<template>
  <main>
    <Body>
      <NuxtLayout>
        <ContentQuery v-slot="{ data }" :path="$route.path" find="one">
          <ContentRenderer :value="data" />
        </ContentQuery>
      </NuxtLayout>
    </Body>
  </main>
</template>
